import React, { useEffect, useState } from "react"
import Typist from 'react-typist';

import 'react-typist/dist/Typist.css'

const Header = () => {
  const [typing, setTyping] = useState(true);

  useEffect(() => {
    setTyping(1);
  }, [typing]);

  return(
    <header id="home" className="custom-section fp-auto-height-responsive">
      <div className="d-flex align-items-center flex-column justify-content-center py-5">
        <div className='px-3 py-5 my-5 sm-text-center'>
          <h1 className="mb-3 pb-2" style={{
            borderBottom: '0.1rem solid white',
          }}>
            Alvin Choong
          </h1>
          <h3>
            I'm a&nbsp;
            {
              typing ? (
                <Typist className='text-secondary d-sm-inline-block' onTypingDone={() => setTyping(false)}>
                  <span>freelancer..</span>
                  <Typist.Backspace count={12} delay={1500} />
                  <span>developer..</span>
                  <Typist.Backspace count={11} delay={1500} />
                  <span>programmer..</span>
                  <Typist.Backspace count={12} delay={1500} />
                </Typist>
              ) : null
            }
          </h3>
        </div>
      </div>
    </header>
  )
}

export default Header
