import React from 'react'

const ServiceCard = ({ title, icon, dataSal, dataSalDuration, dataSalDelay, dataSalEasing, children}) => {
  return (
    <div>
      <div 
        className="d-flex flex-row mb-2"
        data-sal={dataSal}
        data-sal-duration={dataSalDuration}
        data-sal-delay={dataSalDelay}
        data-sal-easing={dataSalEasing}
      >
        <h4 className='my-0 mr-2'>
          { title }
        </h4>
        { icon }
      </div>

      <div 
        className="text-white-50 py-1"
        data-sal={dataSal}
        data-sal-duration={dataSalDuration}
        data-sal-delay={parseInt(dataSalDelay) + 100}
        data-sal-easing={dataSalEasing}
      >
        { children }
      </div>
    </div>
  )
}
export default ServiceCard;