import React from "react"
import PropTypes from "prop-types"

const Section = ({ id, heading, description, className, children }) => {
  const elementId = id ? id : heading.toLowerCase();
  
  return (
    <section id={elementId} className={`m-0 custom-section fp-auto-height-responsive ${className}`}>    
      <div className="d-flex align-items-center flex-column justify-content-center">
        <div className="section-container" >
          <div className="text-center py-4">
            {
              description ? (
                <p className="text-white-50 mb-1">{description}</p>
                ) : null
              }
            <h2>
              {heading}
            </h2>
          </div>
          <div className="mb-4">
            {children}
          </div>
        </div>
      </div>
    </section>
  )
}

Section.defaultProps = {
  description: ``,
  className: ``,
}

Section.propTypes = {
  id: PropTypes.string,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Section

