import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Section from "../sectionTemplate"

const Technology = () => {
  const { nodejs, react, js, gatsby, laravel, aws } = useStaticQuery(graphql`
    query {
      nodejs: file(relativePath: {eq: "tech/nodejs.png"}) {
        name
        childImageSharp {
          fluid(maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      react: file(relativePath: {eq: "tech/react.png"}) {
        name
        childImageSharp {
          fluid(maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      js: file(relativePath: {eq: "tech/js.png"}) {
        name
        childImageSharp {
          fluid(maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      gatsby: file(relativePath: {eq: "tech/gatsby.png"}) {
        name
        childImageSharp {
          fluid(maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      laravel: file(relativePath: {eq: "tech/laravel.png"}) {
        name
        childImageSharp {
          fluid(maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      aws: file(relativePath: {eq: "tech/aws.png"}) {
        name
        childImageSharp {
          fluid(maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `)
  
  return (
    <Section
      heading={`Technologies`}
      description={`Tech I work with`}
    >
      <div className="pb-4 d-flex justify-content-around" data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease">
        <div style={{ maxHeight: '100px', width: '100%' }} className="px-2 d-flex flex-column justify-content-center">
          <Img fluid={nodejs.childImageSharp.fluid} alt={nodejs.name} style={{ maxHeight: "100%" }} imgStyle={{ objectFit: "contain" }}/>
        </div>
        <div style={{ maxHeight: '100px', width: '100%' }} className="px-2 d-flex flex-column justify-content-center">
          <Img fluid={react.childImageSharp.fluid} alt={react.name} style={{ maxHeight: "100%" }} imgStyle={{ objectFit: "contain" }}/>
        </div>
        <div style={{ maxHeight: '100px', width: '80%' }} className="px-2 d-flex flex-column justify-content-center">
          <Img fluid={js.childImageSharp.fluid} alt={js.name} style={{ maxHeight: "75%" }} imgStyle={{ objectFit: "contain" }}/>
        </div>
      </div>

      <div className="pb-4 d-flex justify-content-around" data-sal="slide-up" data-sal-duration="800" data-sal-delay="200" data-sal-easing="ease">
        <div style={{ maxHeight: '100px', width: '100%' }} className="px-2 d-flex flex-column justify-content-center">
          <Img fluid={gatsby.childImageSharp.fluid} alt={gatsby.name} style={{ maxHeight: "100%" }} imgStyle={{ objectFit: "contain" }}/>
        </div>
        <div style={{ maxHeight: '100px', width: '100%' }} className="px-2 d-flex flex-column justify-content-center">
          <Img fluid={laravel.childImageSharp.fluid} alt={laravel.name} style={{ maxHeight: "100%" }} imgStyle={{ objectFit: "contain" }}/>
        </div>
      </div>

      <div className="pb-4 d-flex justify-content-around" data-sal="slide-up" data-sal-duration="800" data-sal-delay="400" data-sal-easing="ease">
        <div style={{ maxHeight: '100px', width: '50%' }} className="px-2 d-flex flex-column justify-content-center">
          <Img fluid={aws.childImageSharp.fluid} alt={aws.name} style={{ maxHeight: "100%" }} imgStyle={{ objectFit: "contain" }}/>
        </div>
      </div>
    </Section>
  )
}

export default Technology