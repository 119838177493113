import React from "react"

import Section from "../sectionTemplate"

const About = () => (
  <Section
    heading={`About`}
    description={`Who am I`}
  >
    <p data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease">
      I'm Alvin Choong, a software engineer who loves building things for the web. I’m working internationally, in-house, and remotely on projects for start ups as well as established companies. I specialize in building and designing extraordinary websites, applications, and everything else in between.
    </p>
    <p data-sal="slide-up" data-sal-duration="800" data-sal-delay="200" data-sal-easing="ease">
      When I built a website, I’m constantly considering how the site will be built and how it will function. This way, you can be sure that both designs and usability will be considered along every step of the way.
    </p>
  </Section>
)

export default About
