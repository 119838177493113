import React from "react"

import Section from "../sectionTemplate"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import ServiceCard from './serviceCard';

import { MdCode, MdBrush, MdCloudQueue, MdLanguage } from 'react-icons/md'

const Services = ({ isResponsive }) => {
  const defaultAnim = { dataSal: "slide-up", dataSalEasing: "ease", dataSalDuration: 800, dataSalDelay: 150 };
  const firstCardAnim = { ...defaultAnim };
  const secondCardAnim = { ...defaultAnim };
  const thirdCardAnim = { ...defaultAnim };
  const fourthCardAnim = { ...defaultAnim };

  if(!isResponsive) {
    firstCardAnim.dataSalDelay = 0;
    secondCardAnim.dataSalDelay = 200;
    thirdCardAnim.dataSalDelay = 400;
    fourthCardAnim.dataSalDelay = 600;
  }

  return (
    <Section
      heading={'Services'}
      description={`What I do`}
    >
      <Row>
        <Col sm={12} md={6}>
          <ServiceCard 
            title='Web Design'
            icon={<MdBrush className={'align-self-end text-secondary'} size="1.5rem" /> }
            {...firstCardAnim}
          >
            <p>You never get a second chance to make a first impression. A website is your online presence.</p>
            <p>I adapt and create websites that provides the best viewing pleasure with interactive mobile responsive designs</p>
          </ServiceCard>
        </Col>
        <Col sm={12} md={6}>
          <ServiceCard
            title='Web Application'
            icon={<MdCode className={'align-self-end text-secondary'} size="1.5rem" /> }
            {...secondCardAnim}
          >
            <p>Automate your stuffs, keep pace in the fast-changing world of technology.</p>
            <p>I code your customized solution with passion and usability in mind, to fufill all your business requirements</p>
          </ServiceCard>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <ServiceCard
            title='Hosting'
            icon={<MdCloudQueue className={'align-self-end text-secondary'} size="1.5rem"/> }
            {...thirdCardAnim}
          >
            <p>Ensure that your website is always up and running.</p>
            <p>Fully managed web hosting that are affordable, fast & secure so you can have peace of mind</p>
          </ServiceCard>
        </Col>
        <Col sm={12} md={6}>
          <ServiceCard
            title='Digital Marketing'
            icon={ <MdLanguage className={'align-self-end text-secondary'} size="1.5rem" /> }
            {...fourthCardAnim}
          >
            <p>Implement calculated strategies that get you qualified leads.</p>
            <p>Increase your search ranking and brand exposure that ultimately get you sales</p>
          </ServiceCard>
        </Col>
      </Row>
    </Section>
  )
}

export default Services