import React from "react"

import Section from "../sectionTemplate"
import ContactForm from "../../Form/contactForm"

const Contact = () => (
  <Section
    heading={`Contact`}
    description={`Let's get started`}
  >
    <div data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease">
      <ContactForm/>
    </div>
  </Section>
)

export default Contact