import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from 'react-bootstrap/Container'

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            name
          }
        }
      }
    `
  )
  
  return (
    <footer>
      <Container fluid>
        <p>{site.siteMetadata.name} &copy; {new Date().getFullYear()}</p>
      </Container>
    </footer>
  )
}

export default Footer