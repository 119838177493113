import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/Header/header"

import About from "../components/Sections/About/about"
import Contact from "../components/Sections/Contact/contact"
import Services from "../components/Sections/Services/services"
import Technology from "../components/Sections/Technology/technology"

const SECTION_SEL = `.custom-section`;
const RESPONSIVE_WIDTH = 768;

class IndexPage extends React.Component {
  constructor() {
    super();
    this.state = {
      isResponsive: true
    };
    this.setResponsive = this.setResponsive.bind(this);
  }

  componentDidMount() {
    this.setResponsive(window.innerWidth < RESPONSIVE_WIDTH);
  }

  setResponsive(isResponsive) {
    this.setState({
      isResponsive: isResponsive
    });
  }

  render() {
    return (
      <Layout>
        <SEO title="Home" />
        <ReactFullpage
          licenseKey={process.env.GATSBY_FULLPAGE_LICENSE}
          navigation={true}
          // anchors={['home', 'about', 'services', 'technologies', 'contact']}
          bigSectionsDestination={'top'}
          responsiveWidth={RESPONSIVE_WIDTH}
          sectionSelector={SECTION_SEL}
          afterResponsive={this.setResponsive}
          render={() => (
            <ReactFullpage.Wrapper>
              <Header />
              <About />
              <Services isResponsive={this.state.isResponsive} />
              <Technology />
              <Contact />
            </ReactFullpage.Wrapper>
          )}
        />
      </Layout>
    );
  }
}

export default IndexPage;