import React, { useState } from "react"

import './topNav.scss'

const TopNav = () => {
  const[menuOpen, setMenuOpen] = useState(false);

  const toggleNav = () => {
    setMenuOpen(!menuOpen);
  }

  const goToIndex = (e, index) => {
    e.preventDefault();
    toggleNav();
    window.fullpage_api.moveTo(index+1);
  }
  const goToSection = (e, page) => {
    e.preventDefault();
    toggleNav();
    document.querySelector(`#${page}`).scrollIntoView({
      behavior: 'smooth',
      duration: 700
    });
  }

  const pages = ['home', 'about', 'services', 'technologies', 'contact'];

  return (
    <div>
      <button onClick={toggleNav} aria-label="Toggle Navigation" className={`toggle-menu ${menuOpen ? 'active' : ''}`}>
        <span></span>
      </button>

      <div id="menu" className={`${menuOpen ? 'open' : ''}`}>
        <nav className="main-nav">
          <ul>
            {pages.map((page, i) => (
              <li key={page}>
                <a className='d-none d-md-block' onClick={(e) => goToIndex(e, i)} href={`#${page}`}>{page}</a>
                <a className='d-md-none' onClick={(e) => goToSection(e, page)} href={`#${page}`}>{page}</a>
              </li>
            ))}
            <li>
              <a href="/resume" target="_blank" rel="noopener noreferrer">Resume</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default TopNav